@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  height: 100vh;
  margin: 0px;
}
#root {
  height: 100vh;
}
.App {
  height: 100%;
}
/* .App > div:first-child {
  height: 100%;
} */
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.cloudimage-360,
.cloudimage-360-inner-box,
.cloudimage-360-icons-container,
.cloudimage-360-icons-container canvas {
  height: 100%;
}
.cloudimage-360-inner-box canvas {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}
.cloudimage-360 {
  margin-bottom: 0px !important;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px;
}
.footer-middle {
  display: flex;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;
  color: #fff;
  width: 500px;

  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.footer-middle h1 {
  font-weight: bolder;
}

.footer-share {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
}
.reserve-my-spot-instantly-popup {
  max-width: 520px;
  width: 100%;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 30px;
  position: relative;
  background-color: white;
  max-width: 400px;
  max-height: 469px;
}
.reserve-my-spot-instantly-popup img {
  position: absolute;
  height: 170px;
  width: 300px;
  top: -24px;
  left: 12%;
  border-radius: 10px;
}
.reserve-my-spot-instantly-popup .img-container {
  padding-top: 130px;
}
.btn-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}
.btn-close-icon {
  background: transparent;
  color: #6b7280;
  border: none;
  font-size: 24px !important;
  padding: 0 !important;
  height: 24px;
  width: 24px;
}
.copy-link {
  background: #0000000a;
  border-radius: 10px;
  padding: 20px 18px 20px 18px;
}
.Demo__container {
  display: flex;
  gap: 5px;
  justify-content: center;
}
.header-img,
.share-image {
  display: none;
}

.footer-img {
  display: flex;
  max-height: 7rem;
  max-width: 7rem;
}

.logo {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  mix-blend-mode: overlay;
}

@media screen and (max-width: 700px) {
  .footer-img {
    display: none;
  }
}

@media screen and (max-width: 700px) {

  .footer-container {
    gap: 0.5rem;
  }

  .header-img {
    width: 100%;
    padding: 10px;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 40px;
  }
}
